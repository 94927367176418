.languages {
    margin-top: 80px;
    color: var(--main-text);
}

.languages h2 {
    text-align: center;
    margin-bottom: 80px;
}

.disclaimer {
    font-size: 16px;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .languages {
        padding: 20px;
    }

    .languages h2 {
        font-size: 24px;
        margin-bottom: 40px;
    }

    .disclaimer {
        font-size: 14px;
    }
}
