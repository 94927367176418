.faq {
    color: var(--main-text);
}

.faq h2 {
    margin-top: 60px;
    margin-bottom: 0;
}

.faq .subtitle {
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 60px;
}

.bigTitle {
    font-size: 48px;
    font-weight: 300;
    margin-top: 120px;
    margin-bottom: 40px;
}

.faq h4 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;
}

.faq p {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .faq {
        padding: 20px;
    }

    .faq h2 {
        margin-top: 0;
        padding-bottom: 0;
        font-size: 18px;
    }

    .faq .bigTitle {
        margin-top: 20px;
        margin-bottom: 0;
        font-size: 24px;
    }

    .faq .subtitle {
        margin-top: 0;
        padding-top: 10px;
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 300;
    }

    .faq p {
        font-size: 14px;
    }
}
