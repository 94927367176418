.school {
    color: var(--main-text);
}

.school h2 {
    margin-top: 60px;
    margin-bottom: 0;
}

.school .subtitle {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
}

.school h3 {
    font-weight: 400;
    text-transform: initial;
    margin-top: 60px;
    margin-bottom: 20px;
}

p {
    margin-top: 0;
}

.contacts {
    margin-top: 120px;
}

@media (max-width: 768px) {
    .school {
        padding: 20px;
    }

    .school h2 {
        margin-top: 0;
        font-size: 18px;
    }

    .school h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: start;
    }

    .school .subtitle {
        padding-top: 0;
    }

    .school p {
        margin-top: 0;
        font-size: 12px;
        font-weight: 300;
    }

    .contacts {
        margin-top: 20px;
    }
}
