.footer {
    margin-top: 120px;
    margin-bottom: 30px;
}

.line {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 40px;
    overflow: hidden;
    margin-top: -15px;
}

.box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
        "text text sponsors sponsors faq faq"
        "copyright copyright copyright terms terms terms";
    margin-top: 40px;
    grid-row-gap: 40px;
    color: var(--main-text);
}

.text {
    grid-area: text;
    font-size: 24px;
}

.sponsors {
    grid-area: sponsors;
    font-size: 18px;
    text-align: center;
    justify-self: center;
}

.sponsors .logos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    margin-top: 30px;
}

.sponsors .logos img {
    height: 56px;
}

.faq {
    display: grid;
    grid-area: faq;
    justify-self: end;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 24px;
    text-align: right;
}

.faq a {
    font-size: 18px;
    font-weight: 300;
    color: var(--primary);
    margin-top: 10px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-self: end;
}

.faq a img {
    margin-left: 10px;
}

.faq a:hover {
    opacity: 0.5;
}

.copyright {
    grid-area: copyright;
    justify-self: start;
    font-size: 14px;
    font-weight: 300;
    color: #72797f;
}

.terms {
    grid-area: terms;
    justify-self: end;
}

.terms a {
    font-size: 14px;
    font-weight: 300;
    color: var(--main-text);
    text-decoration: none;
    margin-left: 20px;
}

@media (max-width: 768px) {
    .line {
        /* height: 20px; */
    }

    .box {
        grid-template-columns: auto;
        grid-template-areas:
            "text"
            "sponsors"
            "faq"
            "terms"
            "copyright";
        justify-items: center;
        grid-gap: 60px;
    }

    .box .text {
        font-size: 18px;
        text-align: center;
    }

    .box .sponsors {
        font-weight: 300;
    }

    .box .sponsors .logos {
        grid-template-columns: auto;
        justify-content: center;
        grid-gap: 20px;
    }

    .box .sponsors .logos img {
        margin: 0 auto;
    }

    .faq {
        grid-gap: 30px;
    }

    .terms a {
        font-size: 18px;
    }

    .faq, .faq a, .terms, .copyright {
        justify-self: center;
    }
}
