.banner {
    background-image: url('../../../images/HERO-IMG.jpg');
    background-size: cover;
    overflow-x: hidden;
}

.box {
    padding: 80px 0;
    color: var(--main-text);
}

.box p {
    font-size: 24px;
    margin: 0;
}

.box h1 {
    margin-top: 10px;
    margin-bottom: 0;
}

.blocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    margin-top: 90px;
}

.blockItem {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    color: var(--main-text);
    /* -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); */
    min-height: 140px;
    padding: 0 20px;
}

.blockItem span {
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}

.line {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 40px;
    overflow: hidden;
    margin-top: -15px;
}

@media (max-width: 768px) {
    .box {
        text-align: center;
    }

    .box h1 {
        font-size: 28px;
    }

    .blocks {
        grid-template-columns: auto;
        padding: 0 20px;
    }

    .blockItem {
        min-height: 60px;
    }
    
    .blockItem span {
        font-size: 16px;
    }
}
