.item {
    color: var(--main-text);
}

.date {
    font-size: 18px;
    font-weight: bold;
    color: #72797f;
    margin-top: 40px;
}

h2 {
    margin-top: 15px;
    margin-bottom: 0;
}

.content {
    margin-top: 40px;
}

.content .image {
    width: 400px;
    height: 260px;
    overflow: hidden;
    background: #ccc;
    float: left;
    margin-right: 40px;
    margin-bottom: 40px;
}

.content .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .item {
        padding: 0 20px;
    }

    h2 {
        padding: 20px 0;
    }

    .content {
        margin: 0;
    }
}
