.item {
    background: #F2F5F8;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 15px;
    color: var(--main-text);
}

.image {
    width: 400px;
    height: 260px;
    overflow: hidden;
    background: #ccc;
}

.image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.box {
    padding: 30px 40px;
}

.date {
    font-size: 18px;
    font-weight: bold;
    color: #72797f;
    margin-bottom: 10px;
}

.title {
    font-size: 24px;
    margin-bottom: 15px;
}

.excerpt {
    font-size: 14px;
    margin-bottom: 30px;
}
.excerpt p {
    margin: 0;
}

.link {
    text-align: end;
}

.link a {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary);
    text-decoration: none;
}

@media (max-width: 768px) {
    .item {
        margin: 20px;
        grid-template-columns: 1fr;
    }

    .item .image, .item .excerpt {
        display: none;
    }

    .item .title {
        font-weight: 300;
    }
}
