@font-face {
    font-family: 'FSRufus';
    src: url(./fonts/fs-rufus/FSRufus-Light.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'FSRufus';
    src: url(./fonts/fs-rufus/FSRufus-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'FSRufus';
    src: url(./fonts/fs-rufus/FSRufus-Bold.ttf) format('truetype');
    font-weight: bold;
}


:global(body) {
    font-family: 'FSRufus';
    font-size: 14px;
}

:root {
    --primary: #fdc600;
    --primary-hover: #FDE27E;
    --neutral: #72797f;
    --main-text: #003b79;
    --block-bg: #F2F5F8;
}

.container {
    margin: 0 auto;
    max-width: 1200px;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 24px;
}

/* h4 {
    font-size: 32px;
} */
