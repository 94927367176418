.levelItem {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 15px;
}

.grade {
    background: #E5EBF1;
    font-size: 28px;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.description {
    background: #F2F5F8;
    font-size: 24px;
    display: flex;
    align-items: center;
    font-weight: 300;
    padding-left: 60px;
}

@media (max-width: 768px) {
    .levelItem {
        grid-template-columns: 1fr;
        margin: 0;
    }
    
    .grade {
        font-size: 24px;
    }
    
    .description {
        min-height: 85px;
        font-size: 14px;
        padding: 20px;
        justify-content: center;
        text-align: center;
    }
}
