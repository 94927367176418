.levels {
    margin-top: 40px;
}

h3 {
    margin: 0;
    margin-bottom: 30px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .levels {
        padding: 0;
    }

    .box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
    }

    h3 {
        text-transform: initial;
        text-align: center;
    }
}
