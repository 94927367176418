.navigation {
    grid-area: navigation;
    display: flex;
    align-items: center;
    justify-self: end;
}

.list {
    padding: 0;
}

.listItem {
    padding: 0;
    display: inline-block;
    padding-left: 40px;
    text-transform: uppercase;
}

.listItem a {
    text-decoration: none;
    color: var(--main-text);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.listItem a:hover {
    color: var(--primary-hover);
}

.listItem a.active {
    color: var(--primary);
}
