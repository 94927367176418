.cookies {
    position: fixed;
    background-color: var(--main-text);
    width: 100%;
    bottom: 0;
}

.box {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 20px 0;
    color: white;
    align-items: center;
}

.text {
    font-size: 14px;
    font-weight: 300;
}

.action a {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    background-color: var(--primary);
    cursor: pointer;
}

@media (max-width: 768px) {
    .box {
        grid-template-columns: 1fr;
        padding: 20px;
    }

    .text {
        font-size: 16px;
        text-align: center;
    }

    .action {
        margin: 20px 0;
    }

    .action a {
        width: 100%;
        font-size: 24px;
        padding: 10px 0;
        display: block;
        text-align: center;
    }
}
