.breadcrumbs {
    background-color: #F2F5F8;
    /* opacity: 0.9; */
}

.box {
    padding: 20px 0;
    color: var(--main-text);
    display: flex;
    align-items: center;
}

.box .back {
    font-size: 16px;
    color: var(--main-text);
    text-decoration: none;
    display: inline-flex;
}

.box .back img {
    margin-right: 10px;
}

.box span {
    margin-left: 60px;
    text-transform: uppercase;
    font-size: 12px;
}

@media (max-width: 768px) {
    .breadcrumbs {
        padding: 0 20px;
    }

    .box {
        display: grid;
        grid-template-columns: auto 1fr;
    }
    
    .box span {
        justify-self: end;
    }
}
