.accordion {
    background: #F2F5F8;
    margin-bottom: 20px;
    color: var(--main-text);
}

.header {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 35px 40px;
    cursor: pointer;
    align-items: center;
}

.header .title {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.actions .image {
    font-size: 60px;
    color: var(--primary);
    width: 40px;
    height: 40px;
    /* background: #CCC; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    padding: 20px 40px;
}

.content a {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 768px) {
    .accordion {
        margin:0;
        margin-bottom: 6px;
    }

    .header {
        padding: 20px;
    }

    .header .title {
        font-size: 14px;
    }

    /* .actions .image {
        width: 30px;
        height: 30px;
    } */
    
    .content {
        padding: 20px;
    }

    .content h4 a {
        font-size: 14px;
    }
}
