.news {
    margin-top: 120px;
}

.news h1 {
    color: var(--main-text);
}

.title {
    margin: 0;
    color: var(--main-text);
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    margin-top: 45px;
    color: var(--main-text);
}

.item {
    display: grid;
    background-color: #F2F5F8;
    padding: 30px 40px;
}

.item .itemDate {
    font-size: 18px;
    font-weight: bold;
    color: var(--neutral);
}

.item .itemTitle {
    font-size: 24px;
    font-weight: 300;
    margin-top: 30px;
}

.item .itemLink {
    justify-self: end;
    margin-top: 20px;
}

.item .itemLink a {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary);
    text-decoration: none;
}

@media (max-width: 768px) {
    .news {
        padding: 0 20px;
    }

    .list {
        grid-template-columns: 1fr;
    }
    
    .item .itemLink a {
        font-size: 18px;
    }
}
