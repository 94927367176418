.privacy {
    color: var(--main-text);
}

.title {
    margin-top: 60px;
}

.subTitle {
    font-size: 24px;
    font-weight: 400;
}

p {
    font-size: 18px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 25px;
    padding: 0;
}

@media (max-width: 768px) {
    .privacy {
        padding: 20px;
    }

    .privacy .title {
        margin-top: 0;
        font-size: 18px;
    }

    .privacy .subTitle {
        padding-top: 0;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .privacy p {
        margin-top: 0;
        font-size: 12px;
        font-weight: 300;
    }
}
