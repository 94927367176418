.news h2 {
    color: var(--main-text);
    margin-top: 60px;
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .news h2 {
        font-size: 18px;
        margin: 0;
        padding: 20px 20px 0 20px;
    }
}
