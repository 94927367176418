.navigation {
    background: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 0;
    z-index: 10;
}

.navigation .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px;
}

.navigation .header img {
    height: 38px;
}

.navigation .header .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-self: end;
}

.navigation ul {

}

.list {
    padding: 0;
    display: grid;
    align-items: center;
}

.listItem {
    padding: 0;
}

.listItem a {
    display: block;
    text-decoration: none;
    color: var(--main-text);
    font-size: 36px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.listItem a.active {
    color: var(--primary);
}
