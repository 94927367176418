.callout {
    margin-top: 80px;
    border: solid 8px var(--main-text);
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 40px 80px;
    align-items: center;
}

.calloutLink {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

.action {
    font-size: 24px;
    font-weight: 300;
    color: var(--primary);
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.action img {
    margin-left: 20px;
}

.action:hover {
    opacity: 0.5;
}

@media (max-width: 768px) {
    .callout {
        margin: 60px 0;
        grid-template-columns: 1fr;
        font-size: 28px;
        padding: 40px 30px;
    }
    
    .callout h2 {
        font-size: 28px;
        padding: 0;
    }

    .calloutAction {
        justify-self: end;
        margin-top: 20px;
    }
}
