.courses {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "courseItem courseItem"
        "callout callout"
        "examsCallout examsCallout";
    grid-gap: 105px;
    color: var(--main-text);
}

.courses h2 {
    text-align: center;
    margin-bottom: 80px;
}

.coursesForAll {
    display: grid;
    grid-area: courseItem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 15px;
    cursor: pointer;
}

.specialCourses {
    display: grid;
    grid-area: courseItem;
    grid-template-rows: 1fr 1fr;
    grid-gap: 15px;
    cursor: pointer;
}

.blockItem {
    display: flex;
    align-items: center;
    color: var(--main-text);
    min-height: 240px;
    padding: 0 20px;
    background-size: cover;
}

.blockItem span {
    width: 100%;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
}

.blockItem strong {
    font-size: 32px;
    font-weight: 400;
}

.blockItem.course1 {
    background-image: url('../../../images/course-1.jpg');
}

.blockItem.course2 {
    background-image: url('../../../images/course-2.jpg');
}

.blockItem.course3 {
    background-image: url('../../../images/course-3.jpg');
}

.blockItem.course4 {
    background-image: url('../../../images/course-4-2.jpg');
}

.blockItem.course5 {
    background-image: url('../../../images/course-5.jpg');
}

.blockItem.course6 {
    background-image: url('../../../images/course-6.jpg');
}

.callout {
    display: grid;
    align-items: center;
    grid-area: callout;
    grid-template-columns: 1fr auto;
    font-size: 32px;
}

.callout span {
    display: block;
    padding-right: 100px;
}

.callout .calloutLink {
    justify-self: end;
}

.callout .calloutLink a {
    color: var(--primary);
    font-weight: 300;
    text-decoration: none;
    border: solid 4px var(--primary);
    padding: 24px 40px;
}

.callout .calloutLink a img {
    margin-left: 40px;
}

.callout .calloutLink a:hover {
    opacity: 0.5;
}

.examsCallout {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-area: examsCallout;
    border: solid 8px var(--main-text);
    padding: 40px;
}

.examsCallout .calloutText {
    font-size: 24px;
    padding-right: 25px;
}

.examsCallout .calloutText h1 {
    text-align: start;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
}

.examsCallout .calloutLink {
    align-self: end;
}

.examsCallout .calloutLink a {
    font-size: 18px;
    color: var(--primary);
    text-decoration: none;
    display: flex;
    align-items: center;
}

.examsCallout .calloutLink a img {
    margin-left: 10px;
}

.examsCallout .calloutLink a:hover {
    opacity: 0.5;
}

@media (max-width: 768px) {
    .courses {
        margin-top: 0;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "courseItem"
            "courseItem"
            "callout"
            "examsCallout";
        grid-gap: 30px;
    }

    .courses h2 {
        font-size: 24px;
        margin-bottom: 0;
    }

    .coursesForAll, .specialCourses {
       padding: 20px;
    }

    .blockItem {
        min-height: 140px;
    }

    .blockItem span {
        font-size: 20px;
    }
    
    .blockItem strong {
        font-size: 24px;
    }

    .callout {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        font-size: 20px;
        text-align: center;
        padding: 0 20px;
    }

    .callout span {
        padding-right: 0;
    }

    .callout .calloutLink {
        margin-top: 40px;
        justify-self: center;
        font-size: 24px;
    }

    .callout .calloutLink a {
        display: block;
        padding: 14px 40px;
    }

    .examsCallout {
        grid-template-columns: 1fr;
        margin: 20px;
        padding: 40px 30px;
    }

    .examsCallout .calloutText {
        font-size: 18px;
    }
    
    .examsCallout .calloutText h1 {
        font-size: 26px;
    }

    .examsCallout .calloutLink {
        justify-self: end;
        margin-top: 20px;
    }

    .examsCallout .calloutLink a {
        font-size: 24px;
    }
}
