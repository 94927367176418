.top {
    position: fixed;
    width: 100%;
    bottom: -40px;
    display: inline-block;
}

.box {
    display: grid;
    justify-items: end;
}

.box a {
    display: grid;
    justify-items: center;
    align-items: center;
    margin-top: -380px;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: #F2F5F8;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}

.box a img {
    transform: rotate(90deg);
}
