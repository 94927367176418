.exams {
    color: var(--main-text);
}

.exams h2 {
    margin-top: 60px;
    margin-bottom: 0;
}

.exams .subtitle {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
}

p {
    font-size: 18px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 25px;
    padding: 0;
}

.logos {
    margin-top: 60px;
    text-align: center;
}

.logosBox {
    display: inline-flex;
}

h1 {
    margin-top: 0;
    margin-bottom: 0;
}

h1.light {
    font-weight: 400;
}

@media (max-width: 768px) {
    .exams {
        padding: 20px;
    }

    .exams h2 {
        margin-top: 0;
        font-size: 18px;
    }

    .exams .subtitle {
        padding-top: 0;
    }

    .exams p {
        padding: 0;
        margin-top: 0;
        font-size: 12px;
    }

    .logosBox {
        display: grid;
    }

    .logosBox img {
        max-width: 100%;
        justify-self: center;
    }
}
