.courses {
    color: var(--main-text);
}

.courses .title {
    margin-top: 60px;
    margin-bottom: 0;
}

p {
    font-size: 18px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 25px;
    padding: 0;
}

.courses h3 {
    text-transform: initial;
}

.marginTop {
    margin-top: 60px;
}

.callout {
    font-size: 32px;
    font-weight: 400;
    margin-top: 0;
}

.courses .callout h2 {
    margin-top: 0;
}

@media (max-width: 768px) {
    .courses {
        padding: 20px;
    }

    .courses .title {
        font-size: 18px;
        margin-top: 0;
    }

    .courses p {
        font-size: 12px;
        margin-top: 0;
    }

    .courses h3 {
        /* text-transform: initial; */
        text-align: left;
    }
}
