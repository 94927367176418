.contacts {
    /* margin-top: 120px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: var(--main-text);
}

.contacts h1 {
    margin-bottom: 45px;
}

.contactLine {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 24px;
    margin-bottom: 30px;
}

.contactLine .icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactLine .text {
    font-size: 24px;
    display: flex;
    align-items: center;
}

.contactLine .text a {
    font-size: 24px;
    text-decoration: none;
    color: var(--main-text);
}

.contactLine span {
    font-size: 18px;
    font-weight: 300;
}

.contactLine span.address {
    font-size: 14px;
    font-weight: 300;
}

.contactLine .link a {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary);
    text-decoration: none;
}

.contactLine .link a:hover {
    color: var(--primary-hover);
}

@media (max-width: 768px) {
    .contacts {
        padding: 20px;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    .contacts h1 {
        font-size: 28px;
    }
}
