:global(.sticky-outer-wrapper.active) .headerWrapper {
    background-color: white;
    height: 60px;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.05);
    transition: height 2s;
}

:global(.sticky-outer-wrapper.active) .headerWrapper .header {
    height: 60px;
    padding: 0;
}

:global(.sticky-outer-wrapper.active) .headerWrapper .logo img {
    height: 32px;
}

:global(.sticky-inner-wrapper) {
    z-index: 1;
}

.header {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "logo navigation navigation";
    /* justify-items: start; */
    padding: 24px 0;
}

.logo {
    grid-area: logo;
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .header {
        padding: 25px 20px;
    }

    :global(.sticky-outer-wrapper.active) .headerWrapper .header {
        height: auto;
        padding: 11px 20px;
    }

    .header .logo img, :global(.sticky-outer-wrapper.active) .headerWrapper .logo img {
        height: 38px;
    }

    .navigation {
        grid-area: navigation;
        display: flex;
        align-items: center;
        justify-self: end;
    }
}
