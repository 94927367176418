.blocks {
    /* margin-top: 80px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 15px;
}

.blockItem {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--block-bg);
    height: 120px;
}

.blockItem span {
    width: 100%;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
}

@media (max-width: 768px) {
    .blocks {
        margin-top: 0;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .blockItem span {
        font-size: 18px;
    }
}
