.line {
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* margin: 0 20px; */
    margin-bottom: 15px;
}

.title {
    background: #E5EBF1;
    font-size: 20px;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-items: center;
}

.description {
    background: #F2F5F8;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    align-items: center;
    padding: 20px 60px;
}

@media (max-width: 768px) {
    .line {
        grid-template-columns: 1fr;
    }

    .title {
        font-size: 16px;
    }

    .description {
        padding: 20px;
        font-size: 14px;
    }
}
